<template>
  <div>
    <!------ plainform area start ------->
    <section class="plainform-area">
      <div class="container">
        <div class="plainformbox">
          <div class="counter">
            <p>{{ $t("search-tab.adult") }}</p>
            <input type="text" class="form-control" v-model="countAdult" @change="changePaxCount"/>
            <button class="btn-count" @click="changePaxes('adult', 'plus')">+</button>
            <button class="btn-count" @click="changePaxes('adult', 'subtract')">-</button>
          </div>
          <div class="counter">
            <p>{{ $t("search-tab.child") }}</p>
            <input type="text" class="form-control" v-model="countChild" @change="changePaxCount"/>
            <button class="btn-count" @click="changePaxes('child', 'plus')">+</button>
            <button class="btn-count" @click="changePaxes('child', 'subtract')">-</button>
          </div>
          <div class="counter">
            <p>{{ $t("product-page.infant") }}</p>
            <input type="text" class="form-control" v-model="countInfant" @change="changePaxCount"/>
            <button class="btn-count" @click="changePaxes('infant', 'plus')">+</button>
            <button class="btn-count" @click="changePaxes('infant', 'subtract')">-</button>
          </div>
        </div>
        <div class="btn-group">
          <button class="btn-primary">סה“כ לתשלום</button>
        </div>
      </div>
    </section>
    <!------ plainform area stop ------->
    <!----- order area start ----->
    <section class="order-area" v-if="device==='desktop'">
      <div class="container">
        <div class="order-list d-flex">
          <ul>
            <li>
              {{ $t('product-page.total-per-passenger') }}
              <strong>{{getPriceWithSymbol(currencySymbol, average)}}</strong>
            </li>
            <li>
              {{ $t("flight-only.total-to-pay") }}
              <strong>{{getPriceWithSymbol(currencySymbol, total)}}</strong>
            </li>
          </ul>
          <button class="btn-primary order" @click="gotoBook" :disabled="disableBook">הזמן עכשיו</button>
        </div>
      </div>
    </section>
    <section class="order-area" v-else>
      <div class="container">
        <div class="order-list">
          <ul>
            <li>
              {{ $t('product-page.total-per-passenger') }}
              <strong>{{getPriceWithSymbol(currencySymbol, average)}}</strong>
            </li>
            <li>
              {{ $t("flight-only.total-to-pay") }}
              <strong>{{getPriceWithSymbol(currencySymbol, total)}}</strong>
            </li>
          </ul>
        </div>
        <div class="btn-box">
          <button class="btn-primary" @click="gotoBook" :disabled="disableBook">הזמן עכשיו</button>
        </div>
      </div>
    </section>
    <!----- order area stop ----->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';

export default {
  components: {
  },
  props: {
    occupancy: {
      type: Object,
      default: null,
    },
    airlineNotConfirmed: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    average: {
      type: Number,
      default: 0,
    },
    infoFlight: {
      type: Object,
      default: () => ({
        name: 'Blue Bird Air...',
        description: 'Lorem ipsum dolor sit amet,',
      }),
    },
    currencySymbol: {
      type: String,
      default: 'US',
    },
  },
  mixins: [gMixin],
  data() {
    return {
      query: '',
      totalPrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      activeFlightId: 'GET_FLIGHT_ID',
    }),
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult
          ? this.$store.getters.GET_SEARCH_CONTENT.adult
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child
          ? this.$store.getters.GET_SEARCH_CONTENT.child
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant
          ? this.$store.getters.GET_SEARCH_CONTENT.infant
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    disableBook() {
      return this.activeFlightId === '';
    },
  },
  created() {
    this.query = this.$route.query;
    this.countAdult = this.query.adult;
    this.countChild = this.query.child;
    this.countInfant = this.query.infant;
  },
  methods: {
    changePaxes(pKindPax, pKindChange) {
      const diff = pKindChange === 'plus' ? 1 : -1;
      switch (pKindPax) {
        case 'adult':
          this.countAdult = Number(this.countAdult) + diff;
          if (this.countAdult < 0) this.countAdult = 0;
          break;
        case 'child':
          this.countChild = Number(this.countChild) + diff;
          if (this.countChild < 0) this.countChild = 0;
          break;
        case 'infant':
          this.countInfant = Number(this.countInfant) + diff;
          if (this.countInfant < 0) this.countInfant = 0;
          break;
        default:
      }
      this.$emit('emitUpdatePax');
    },
    changePaxCount() {
      // console.log(this.countAdult, this.countChild, this.countInfant);
      this.$emit('emitUpdatePax');
    },
    gotoBook() {
      this.$emit('emitBook');
    },
  },
};
</script>

<style lang="less" scoped>
  .bonauf-desktop {
    .flight_box_three {
      .plainformbox {
        max-width: 40%;
      }
    }
    .order-area {
      position: fixed;
      bottom: 0px;
      width: 100%;
      background: #fff;
      z-index: 9;

      .order-list {
        li {
          font-family: 'ploniregularaaa';
          font-size: 28px;
          color: #000;

          strong {
            display: block;
            font-family: 'FbCoherentiSansBold';
            font-size: 30px;
          }
        }
      }

      .order {
        height: 50px;
        width: 160px;
        margin: auto;
      }
    }
  }
</style>

<style scoped>
.right_box {
  margin: auto;
  width: 50%;
}
.right_box div.form-group {
  padding: 2px;
  margin: auto;
}

@media (max-width: 476px) {
  .flight_box_three_body_two .right_box .d-flex {
    display: inline-block !important;
    padding: 0px;
  }
  .right_box > div.col-6 {
    padding: 2px;
  }
  .right_box div.form-group {
    padding: 0px;
    max-width: 100%;
  }
  .flight_only_body .flight_box_three .flight_box_three_body_two .right_box {
    width: 100%;
  }

  .flight_box_three_body_two .right_box h5 {
    font-size: 12px;
  }
  .flight_box_three_body_two .right_box h3 {
    font-size: 21px;
  }
}
</style>
